<template>
  <!-- In progress -->
  <div v-if="inProgress" class="text-center">
    <h2>{{ $t('loadingtxt') }}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
  </div>
  <div v-else class="row">
    <div class="card col-md-12">
      <div class="card-header">
        <h5>
          <i class="fa fa-users"></i>
          {{ txt.userManager }}
        </h5>
      </div>

      <hr>
      <div class="card-body">
        <div class="list">
          <el-container class="module-body">
            <el-main>
              <data-tables-server ref="datatable"
                                  :data="data"
                                  :total="total"
                                  :table-props="configTable"
                                  @query-change="loadData"
                                  @current-change="setCurrentRow">
                <div slot="empty">{{ $t('norecordsfounded') }}</div>
                <el-table-column :label="$t('userid')" align="left">
                  <template slot-scope="props">
                    {{ props.row.userid }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('nametxt')" align="left">
                  <template slot-scope="props">
                    {{ props.row.full_name }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('address')" align="center">
                  <template slot-scope="props">
                    {{ props.row.address }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('zip')" align="center">
                  <template slot-scope="props">
                    {{ props.row.postal }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('country')" align="center">
                  <template slot-scope="props">
                    {{ props.row.country }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('phonetxt')" align="center">
                  <template slot-scope="props">
                    {{ props.row.phone }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('birthdatetxt')" align="center">
                  <template slot-scope="props">
                    {{ props.row.birth_date }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('last_login')" align="center">
                  <template slot-scope="props">
                    {{ props.row.last_login }}
                  </template>
                </el-table-column>
                  <el-table-column :label="$t('balance')" align="center">
                  <template slot-scope="props">
                    {{ props.row.balance }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('number_of_accountstxt')" align="center">
                  <template slot-scope="props">
                    {{ props.row.accounts }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('number_of_vps')" align="center">
                  <template slot-scope="props">
                    {{ props.row.vpss }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('affiliatetxt')" align="center">
                  <template slot-scope="props">
                    {{ props.row.affiliate }}
                  </template>
                </el-table-column>
              </data-tables-server>
            </el-main>
          </el-container>
        </div>
      </div>
      <div class="card-footer">

      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import {DataTablesServer} from 'vue-data-tables';
import {Loading, Pagination, Container, Aside} from "element-ui";
import {mapActions, mapState} from "vuex";
import {ListPayload} from "@/models/commons";

Vue.use(Aside);
Vue.use(Container);
Vue.use(DataTablesServer);
Vue.use(Loading);
Vue.use(Pagination);


export default {
  name: "UserManager",
  components: {},
  computed: {
    ...mapState('users_manager', ['currentRow']),
    configTable() {
      return {
        height: document.querySelector('.content').clientHeight - 160,
        highlightCurrentRow: true,
        stripe: true,
      };
    }
  },
  data() {
    return {
      inProgress: true,
      data: [],
      total: 0,
      filters: {
        email: '',
      },
      txt: {
        userManager: 'User Manager'
      }
    }
  },
  methods: {
    ...mapActions('users_manager', ['setCurrentRow']),
    loadData(payload) {
      if (payload.page === null) {
        return;
      }
      this.managerUsers({
        payload: this.$serializeToQueryString(new ListPayload(payload))
      }).then(this.loadUsers, this.failop)
    },
    loadUsers(resp){
      if(resp.success) {
        this.data = resp.data.results
        this.total = resp.data.length;
        this.inProgress = false
      }
    },
    failop(resp) {

    }
  },
  mounted() {
    this.inProgress = false
  },
  watch: {
    currentRow(value) {
      if (value && !value.template) {
        this.$refs.datatable.queryChange('refresh');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';

h5 {
  display: inline-block;
  padding: 1rem;
  margin: 0;

  i {
    padding-right: 1rem;
  }
}

hr {
  margin: 0 !important;
}

.card-body {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.el-main {
  padding-top: 0;
  padding-bottom: 0;
}

.el-aside {
  border-left: $border $light-gray;

  fieldset {
    padding: $padding-input-vertical 0 0 $padding-input-vertical;

    > label {
      font-weight: bold;
      font-size: $font-size-medium;
      color: $font-color;
    }
  }
}
</style>
